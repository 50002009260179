<script>
import {defineComponent} from 'vue';
import OcsMainBlock from '@/components/dynamic/ocsMainBlock.vue';
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';

export default defineComponent({
  name: 'ConnectionsView',
  components: {
    OcsMainBlock,
    PhpServiceView,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <PhpServiceView :url="url" #default="{result}">
    <OcsMainBlock :result="result"/>
  </PhpServiceView>
</template>

<style scoped>

</style>