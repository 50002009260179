<template>
  <primaryButton
    class="mr-2"
    :attributesArray="{ ...functionElement['attributesArray'], id: id }"
    type="button"
    :value="functionElement['functionName']"
    v-on="$listeners"
    :label="label"
    color="save"
    :disabled="!isValid || loading"
    :loading="loading"
    icon="mdi-content-copy"
  ></primaryButton>
</template>

<script>
import primaryButton from "@/commonComponents/primaryButton.vue";

export default {
  props: ["isValid", "loading", "functionElement", "result", "id"],
  components: {
    primaryButton,
  },
  computed: {
    label() {
      let label = this.functionElement["functionName"];
      if (
        typeof this.result.valueLine.domains != "undefined" &&
        this.result.valueLine.domains != "" &&
        isNaN(this.result.valueLine.domains)
      ) {
        label = "Copy to New";
      }
      return label;
    },
  },
};
</script>
