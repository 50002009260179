<template>
  <pre v-html="prettyJson"/>
</template>

<script>
import { prettyPrintJSON } from "@/js/general";

export default {
  name: 'PrettyJson',
  props: {
    value: undefined,
  },
  data() {
    return {
      prettyPrintJSON: prettyPrintJSON,
    }
  },
  computed: {
    prettyJson() {
      let value = this.value;
      if (!value) return '';
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      return this.prettyPrintJSON(value);
    },
  },
};
</script>

<style scoped>

</style>