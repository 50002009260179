<template>
  <div>
    <router-link
      v-if="typeof result.fields['n_testgroup_id']['value']!='undefined'"
      class="testGroupLink"
      :to="{ name: 'test-group', params: { id: result.fields['n_testgroup_id']['value'] }}"
    >{{result.fields['n_testgroup_id']['value']}}</router-link>
    <label
      v-if="typeof result.fields['n_id']['value']!='undefined'"
    >- {{result.fields['n_id']['value']}}</label>
  </div>
</template>
<script>
export default {
  props: ["result"],
};
</script>