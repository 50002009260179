<template>
  <div>
    <confirmationDialog
      :hideCancelButton="true"
      :value="showErrorDialog"
      title="Error"
      message="Error Running test. Please retry later"
      @confirmation-ok="showErrorDialog = !showErrorDialog"
      maxWidth="290"
    ></confirmationDialog>

    <startParametersDialog
      v-if="parameterDialog"
      v-model="startParameters"
      :parameterDialog="parameterDialog"
      :loading="loading"
      @confirmation-ok="
        runTest(parameters.n_id, parameters.n_id_key, ...arguments)
      "
      :testNames="testNames"
    >
    </startParametersDialog>
  </div>
</template>
<script>
import { runTest } from "@/js/helper.js";
import startParametersDialog from "@/components/legacy/startParametersDialog.vue";
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
export default {
  components: { startParametersDialog,confirmationDialog },
  data() {
    return {
      parameterDialog: false,
      showErrorDialog: false,
      startParameters: [],
      parameters: {},
    };
  },

  created() {  //start a test and redirect start by runTest //Store dont have data or this components is not rendered!
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "checkStartParameters") {
        this.parameters = state.checkStartParameters;
        this.runTest(this.parameters.n_id, this.parameters.n_id_key, true);
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    runTest(n_id, n_id_key, execute) {
      if (execute) {
        runTest(n_id, n_id_key, this);
      } else {
        this.parameterDialog = false;
      }
    },
  },
};
</script>