<template>
  <div class="msisdns-view">
    <XDataTable
        title="MSISDNs"
        :items-request="cockpitSimService.getMsisdns"
        :headers="headers"
        :crud-requests="crudRequests"
        name-header="msisdn"
        history
        dialog-width="500"
        new-button
        item-name="MSISDN">
      <template #dialog="{value, id, version, save, close}">
        <MsisdnDialog :value="value" :id="id" :version="version" @save="save" @input="close"/>
      </template>
    </XDataTable>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable';
import MsisdnDialog from '@/components/specific/MsisdnDialog.vue';
import cockpitSimService from "@/js/services/CockpitSimService";
import {unixToDateTimeString} from '@/js/general';

export default {
  name: 'MsisdnsView',
  components: {
    MsisdnDialog,
    XDataTable,
  },
  data() {
    return {
      cockpitSimService: cockpitSimService,
      dialog: false,
      headers: [
        {
          text: 'MSISDN',
          value: 'msisdn',
          sortable: true,
        },
        {
          text: 'MCCMNC',
          value: 'mccmnc',
          sortable: true,
        },
        {
          text: 'Description',
          value: 'description',
          sortable: true,
        },
        {
          text: 'MNP Aging',
          value: 'mnpAging',
          sortable: false,
          formatter: unixToDateTimeString,
        },
      ],
    };
  },
  computed: {
    crudRequests() {
      return {
        delete: {
          request: cockpitSimService.deleteMsisdn,
        },
      };
    },
  },
};
</script>

<style scoped>
.msisdns-view {
  display: contents;
}
</style>
