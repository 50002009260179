<template>
  <div>
    <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>

    <primaryButton
        class="mr-2"
        :attributesArray="functionElement['attributesArray']"
        type="button"
        :value="functionElement['functionName']"
        @click="restart"
        :label="functionElement['functionName']"
        color="save"
        :disabled="loading"
        :loading="loading"
        icon="mdi-move-resize-variant"
        :shrink="functionElement['shrink']"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"
import { useSwitchContext } from "@/composition/user/use-switch-context"
import primaryButton from '@/commonComponents/primaryButton.vue';
import standbyDialog from '@/commonComponents/standbyDialog.vue';

export default defineComponent({
  props: ['isValid', 'loading', 'functionElement', 'result'],

  components: {
    primaryButton,
    standbyDialog,
  },

  setup(props) {
    const { doContextSwitch } = useSwitchContext()
    const localLoadingDialog = ref(false)

    const restart = async () => {
      localLoadingDialog.value = true;

      const projID = props.result.valueLine.projects
      const orgID = props.result.valueLine.organisations

      try {
        await doContextSwitch({ projID, orgID })
        location.reload()
      } finally {
        localLoadingDialog.value = false;
      }
    }

    return {
      localLoadingDialog,
      restart,
    }
  },
});
</script>
