<template>
<div>
    <v-card
            class="mx-auto"
            max-width="715px"
    >
        <v-card-text>
            <p class="text-h6 text--primary">Hello {{result.fullName}}</p>
            <p style="margin:0px;font-weight:bold;">We are excited to welcome you to the Acctopus Degust user community.</p>
            <p style="font-weight:bold;">If <span style="color: green;">you are new to Degust</span>, we recommend reading this in full.</p>

            <p  style="margin:0px;">To kickstart your experience with Degust, we have <router-link :to="{ name: 'home' }">provided a test suite (called project)</router-link> with some tests and graphs. These tests are real tests, running on some of our Explorers in a tiny demo Project. Keep in mind, there are enterprises, managing thousands of Explorers using Degust, which will give a lot more output.</p>
              <p> The Explorers and their local statistics can be viewed under <router-link :to="{ name: 'explorers' }">Explorers</router-link>.</p>

            <p> You can open and view the test configuration and evaluation at <router-link :to="{ name: 'configure-and-run-tests' }">Configure and Run Tests</router-link>. Later, you may copy them to one of your own projects and modify them to your requirements using your own Explorers.</p>

            <p>To create your own Explorer, you may <router-link :to="{ name: 'explorers' }">follow this link</router-link> to download the most current version of our free-of-charge Explorer image for your Raspberry Pi 4 or Pi 3B+. While creating the OS image, we are creating a project and some tests, scheduled to run on Explorers running this customized OS.</p>

            <p>At any time, you can modify this new project or its tests or create your own project under <router-link :to="{ name: 'my-projects' }">My Projects</router-link> in the Administration menu. Here you can also add members (coworkers or customers) to your project.</p>

            <p>If other Degust users inviting you to existing projects, you may switch to these projects using the project and organization switcher in the top-right menu of the Degust Cockpit.</p>

            <p>You may alter your personal profile under <router-link :to="{name: 'my-account',}">My Account</router-link> where you add additional security including the two-factor authentication.</p>

            <p>Please contact us if you have any questions, we will be happy to help you.</p>
            <p style="margin:0px;">Thank you</p>
            <p>Your Acctopus team</p>
        </v-card-text>
       <div class="ml-4 pb-7">
           <router-link :to="{ name: 'home' }">
           <primaryButton
                   type="button"
                   label="Go to Dashboard"
                   color="accent"
                   id="dashboard"
           ></primaryButton>
           </router-link>
       </div>

    </v-card>
</div>
</template>
<script>
  //import tfaInfoContent from "@/components/tfaInfoContent.vue";
   import primaryButton from "@/commonComponents/primaryButton.vue";
  export default {
    props: ["result"],
    data:function(){
      return {
      };
    },
    components:{
      primaryButton
      //tfaInfoContent
    },
    methods: {

    },
  };
</script>
