<template>
  <div>
    <!--  <fieldsRows :templateContent="result"></fieldsRows>    -->
    <v-row no-gutters>
      <v-col sm="7" cols="12" align="start">
        <v-row no-gutters class="pb-2">
          <v-col style="max-width: 147px" class="font-weight-bold">
            {{ result.fields.testNo.name }}
          </v-col>
          <v-col
            ><testgroupPathFormatter :result="result"> </testgroupPathFormatter
          ></v-col>
        </v-row>
        <v-row
          class="pb-2"
          no-gutters
          v-if="typeof result.fields.c_subscriber != 'undefined'"
        >
          <v-col style="max-width: 140px" class="font-weight-bold">
            {{ result.fields.c_subscriber.name }}
          </v-col>
          <v-col class="ml-2">

            <testInfoSubscriberFormatter :valueLine="result.valueLine" :result="result">
            </testInfoSubscriberFormatter>
          </v-col>
        </v-row>
        <v-row class="pb-2" no-gutters>
          <v-col style="max-width: 140px" class="font-weight-bold">
            {{ result.fields.c_description.name }}
          </v-col>
          <v-col class="ml-2">
            {{ result.valueLine.c_description }}
          </v-col>
        </v-row>
        <v-row class="" no-gutters style="height: 23px">
          <v-col style="max-width: 140px" class="font-weight-bold">
            {{ result.fields.n_type.name }}
          </v-col>
          <v-col class="ml-2" style=""
            >

            <defaultFieldTypes
              :fieldAttributes="result.fields.n_type"
              :field="'n_type'"
              :templateContent="result"
            ></defaultFieldTypes
          >
          </v-col>
        </v-row>
        <v-row class="pb-2 pt-2" no-gutters>
          <v-col style="max-width: 147px" class="font-weight-bold">
            {{ result.fields.date.name }}
          </v-col>
          <v-col>
            <testInfoDate
              :fieldAttributes="result.fields.date"
              :value="result.valueLine.date"
            >
            </testInfoDate
          ></v-col>
        </v-row>
        <v-row class="pb-2" no-gutters>
          <v-col style="max-width: 140px" class="font-weight-bold">
            {{ result.fields.executer.name }}
          </v-col>
          <v-col class="ml-2">
            <div  style="width: fit-content" v-if="typeof result.valueLine!='undefined' &&
                                                typeof result.valueLine.executer!='undefined' &&
                                                typeof result.valueLine.executer.fName!='undefined' &&
                                                typeof result.valueLine.executer!=''
">
              {{ result.valueLine.executer.fName }}
              {{ result.valueLine.executer.lName }}
            </div>
            <div v-else >
              API Service
            </div>
          </v-col>
        </v-row>
        <v-row class="pb-2" no-gutters>
          <v-col style="max-width: 147px" class="font-weight-bold">
            {{ result.fields.n_status.name }}
          </v-col>
          <v-col>
            <testStatusFormatter :result="result"> </testStatusFormatter>
            <span
              v-if="result.content.includes('testInfoReloader')"
              class="reloadtimer"
              style="
                visibility: visible;
                position: relative;
                left: 100px;
                top: -15px;
              "
            >
              <img style="width: 16px; height: 16px" src="/img/radio.gif" />
            </span>
          </v-col>
        </v-row>
        <v-row  v-if="typeof result.fields['c_resultInfo']!=='undefined' &&
                typeof result.fields['c_resultInfo']['value']!=='undefined' &&
                result.fields['c_resultInfo']['value']!='' " class="pb-2" no-gutters>
          <v-col style="max-width: 147px" class="font-weight-bold">
            Result Info
          </v-col>
          <v-col>
              <label>{{this.result.fields['c_resultInfo']['value']}}</label>
          </v-col>
        </v-row>
      </v-col>
      <v-divider
        vertical
        class="ml-4 hidden-sm-and-down"
        color="#a2a2a2"
        style="max-width: 2px; width: 1.5px"
      ></v-divider>
      <v-col>
        <v-row no-gutters v-if="showVariables">
          <v-col style="max-width: 100px" class="font-weight-bold ml-4">
              {{ result.fields.c_testVariables.name }}
          </v-col>
          <testVariables
            :fieldAttributes="result.fields.c_testVariables"
            :value="result.valueLine.c_testVariables"
            :result="result"
          ></testVariables>
        </v-row>
      </v-col>
    </v-row>
    <v-divider
      class="mb-2"
      color="#a2a2a2"
      style="max-height: 2px; height: 1.5px"
    ></v-divider>
    <template v-if="mini != true">
      <v-row>
        <v-col sm="5" cols="12" style="min-width: fit-content">
             <spaceButtonsFormatter
              :result="result"
              :fieldAttributes="result.fields.c_space"
              :value="result.valueLine.c_space"
            ></spaceButtonsFormatter>
         </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <div>
            <searchInput
              :minify="false"
              :value="localSearch"
              @input="updateSearchValue"
            ></searchInput>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </template>
  </div>
</template>
<script>
import searchInput from "@/commonComponents/searchInput.vue";
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import testVariables from "@/components/legacy/testVariables.vue";
import testInfoDate from "@/components/legacy/testInfoDate.vue";
import testStatusFormatter from "@/components/legacy/testStatusFormatter.vue";
import testgroupPathFormatter from "@/components/legacy/testgroupPathFormatter.vue";
import testInfoSubscriberFormatter from "@/components/legacy/testInfoSubscriberFormatter.vue";
import spaceButtonsFormatter from "@/components/legacy/spaceButtonsFormatter.vue";
//import avatar from "@/components/avatar.vue";
export default {
  components: {
    searchInput,
    defaultFieldTypes,
    testVariables,
    testInfoDate,
    testStatusFormatter,
    testgroupPathFormatter,
    spaceButtonsFormatter,
    testInfoSubscriberFormatter,
    //  avatar,
  },
  props: ["result", "localSearch", "mini"],
  computed:{
    showVariables(){
      let show = false;
      if((typeof this.result.valueLine.c_testVariables!='undefined' &&
                 this.result.valueLine.c_testVariables!=null &&
          Object.keys(this.result.valueLine.c_testVariables).length > 0)  ||
         (typeof this.result.globalTestVariables!='undefined' &&
                 this.result.globalTestVariables!=null &&
          Object.keys(this.result.globalTestVariables).length>0)){
        show = true;
      }
      return show;
    }
  },
  methods: {
    updateSearchValue(value) {
      this.$emit("update-search", value);
    },
  },
};
</script>
<style scoped>
.v-tooltip__content {
  left: 250px !important;
  top: 275px !important;
}
</style>