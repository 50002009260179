<script setup>
import {defineProps, defineEmits, ref, watch} from 'vue';
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import Table from '@/commonComponents/dynamic/table.vue';
import {useRoute} from 'vue-router/composables';
import {shaKey} from '@/js/helper';
import {useStore} from '@/store';

const store = useStore();
const route = useRoute();

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['result']);

const _url = ref('');

watch(() => route.query, () => {
  const id = route.query['history-for'];
  if (!id) {
    _url.value = props.url;
    return;
  }

  _url.value = `${props.url}&n_id=${id}&n_id_key=${shaKey(id)}&function=showsHistoryContent`;
}, {immediate: true});

const changeTable = (result) => {
  store.commit('tableChanged', result.table);
  emit('result', result);
};
</script>

<template>
  <PhpServiceView :key="_url" :url="_url" #default="{ result }" @result="changeTable">
    <Table :result="result"/>
  </PhpServiceView>
</template>

<style lang="scss">
:root {
  --main-v3-max-height: calc(var(--app-main-height) + 30px)
}
</style>
