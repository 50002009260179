<template>
  <div style="display: contents;">
    <XDataTable
        :headers="headers"
        title="SIP Subscribers"
        item-name="SIP subscriber"
        :items-request="sipSubscriberService.getSipSubscribersTable"
        :crud-requests="crudRequests"
        :item="item"
        :default-item="createDefaultItem()"
        search
        history
        @update:item="item = $event"
        dialog-width="541">
      <template #dialog-form>
        <div class="sip-subscribers-form">
          <XTextField v-model="item.name" label="Name" required validate-immediately/>
          <XTextarea v-model="item.description" label="Description"/>
          <HeadlineBox headline="SIP Parameters">
            <div class="sip-parameters">
              <XTextField v-model="item.parameters.subscriber" label="Subscriber Number"/>
              <XTextField v-model="item.parameters.user" label="User" required/>
              <XTextField v-model="item.parameters.password" label="Password" type="password" required/>
              <XTextField v-model="item.parameters.registrar" label="Registrar" required/>
              <XTextField v-model="item.parameters.sipId" label="SIP ID" required/>
              <XTextField v-model="item.parameters.stunServer" label="STUN-Server"/>
              <div class="sip-parameters-bottom">
                <XCheckboxGroup v-model="item.subType" label="Subtype" :items="subTypes"/>
                <XRadioGroup
                    v-if="item.subType && item.subType.includes('in')"
                    v-model="item.parameters.resourceAs"
                    label="Resource as"
                    :items="resourceAsItems"
                    row
                    required
                    class="resource-as"/>
              </div>
            </div>
          </HeadlineBox>
        </div>
      </template>
    </XDataTable>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable';
import XTextField from '@/components/basic/XTextField';
import XTextarea from '@/components/basic/XTextarea';
import HeadlineBox from '@/components/basic/HeadlineBox';
import XCheckboxGroup from '@/components/basic/XCheckboxGroup';
import XRadioGroup from '@/components/basic/XRadioGroup';
import sipSubscriberService from "@/js/services/SipSubscriberService";

export default {
  name: 'SipSubscribers',
  components: {
    XRadioGroup,
    XCheckboxGroup,
    HeadlineBox,
    XTextarea,
    XTextField,
    XDataTable,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Description',
          value: 'description',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          formatter: (value, row) => {
            return `${value}${row['subType'] ? ' ' + row['subType'].join(' & ').toUpperCase() : ''}`;
          },
        },
        {
          text: 'User',
          value: 'parameters',
          formatter: (parameters) => parameters.user,
        },
        {
          text: 'Registrar',
          value: 'parameters',
          formatter: (parameters) => parameters.registrar,
        },
        {
          text: 'SIP ID',
          value: 'parameters',
          formatter: (parameters) => parameters.sipId,
        },
      ],
      item: this.createDefaultItem(),
      dialogLoading: false,
      subTypes: [
        {
          text: 'In',
          value: 'in',
        },
        {
          text: 'Out',
          value: 'out',
        },
      ],
      explorerTags: [],
      resourceAsItems: [
        {
          text: 'Subscriber Number',
          value: 'subscriber',
        },
        {
          text: 'SIP ID',
          value: 'sipid',
        },
      ],
      sipSubscriberService: sipSubscriberService,
    };
  },
  computed: {
    crudRequests() {
      return {
        create: {
          request: sipSubscriberService.createSipSubscriber,
        },
        read: {
          request: sipSubscriberService.getSipSubscriber,
        },
        update: {
          request: sipSubscriberService.updateSipSubscriber,
        },
        delete: {
          request: sipSubscriberService.deleteSipSubscriber,
        },
      };
    },
  },
  methods: {
    createDefaultItem() {
      return {
        id: 0,
        name: '',
        description: '',
        subType: [],
        category: '',
        parameters: {
          subscriber: '',
          user: '',
          password: '',
          registrar: '',
          sipId: '',
          stunServer: '',
          resourceAs: '',
        },
      };
    },
  },
};
</script>

<style scoped>
.sip-subscribers-form,
.sip-parameters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sip-parameters-bottom {
  display: flex;
  gap: 16px;
}

.resource-as {
  padding-top: 24px;
}

</style>
