<template>
  <div class="dropdown-PDF">
    <v-btn
        color="save"
        elevation="0"
        min-width="130"
        min-height="40"
        class="white--text"
        :loading="loadingAndDisabled"
        :disabled="loadingAndDisabled"
        @click.stop="openPDF('PDF')"
    >
      <v-icon left size="26">mdi-file-pdf-box</v-icon>
      {{ language.componentLabel.labelReportType }}
    </v-btn>
    <!--    <v-menu
            open-on-hover
            top
            offset-y
            close-on-click
            elevation="0"

        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="save"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                min-width="130"
                min-height="40"
                class="white&#45;&#45;text"
                :loading="loadingAndDisabled"
                :disabled="loadingAndDisabled"
            >
              <v-icon left size="26">mdi-file-pdf-box</v-icon>
              {{ language.componentLabel.labelReportType }}
            </v-btn>

          </template>
          <v-list>
            <v-list-item
                @click.stop="openPDF('PDFSimple')"
            >
              <v-list-item-title>{{ language.componentLabel.labelOverviewType }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click.stop="openPDF('PDF')"
            >
              <v-list-item-title>{{ language.componentLabel.labelDetailedType }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>-->
  </div>
</template>
<script>
import {tokenRefresher} from '@/auth-tools';
import {dateToCondensedIsoDateTimeString} from '@/js/general';

export default {
  props: ['buttonAttrs'],
  components: {},
  data: function () {
    return {
      doHide: false,
    };
  },
  computed: {
    loadingAndDisabled() {
      return this.doHide;
    },
  },
  methods: {
    openPDF: function (type) {
      this.doHide = true;
      const url = `${process.env.VUE_APP_PHP_SERVICE}/serve.php?f=testing&f2=testInfo&function=${type}`
          + `&n_id=${this.buttonAttrs['n_id']}&n_id_key=${this.buttonAttrs['n_id_key']}`;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          ...tokenRefresher.authorizationHeader,
        },
      }).then(response => response.blob()).then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `DetailedReport_${this.buttonAttrs['n_id']}_${dateToCondensedIsoDateTimeString(new Date())}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        this.doHide = false;
      }).catch(error => console.error('Error downloading the file:', error));
    },
  },
};
</script>
<style scoped>
.backgroundBlur {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: hsl(0deg 0% 100% / 70%);
  height: 100%;
  width: 100%;
}

/* .loaderImg{
   width: 45px;
   position: fixed;
   top: 50%;
   left: 50%;
   z-index: 999999;
 }*/
.loaderImg {
  width: 32px;
  position: absolute;
  z-index: 999999;
  bottom: 7px;
}
</style>
