<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
import { defineProps, defineEmits, useAttrs, ref } from "vue";
import UiInputWrapper from "./ui/UiInputWrapper.vue";
import { convertByte2MB } from "@/js/helper";

const props = defineProps({
  file: {
    type: File,
    default: null,
  },
  label: {
    type: String,
    required: true,
  },
  maxSize: {
    type: Number,
    default: Infinity,
  },
  hints: {
    type: String,
    default: "",
  },
  errors: {
    type: String,
    default: "",
  }
});

const emit = defineEmits(["update:file"])

const attrs = useAttrs();
const filteredAttrs = (() => {
  const { class: _, style: __, ...otherAttrs } = attrs;
  return otherAttrs;
})();

const fileName = ref(props.file?.name || "");
const fileInput = ref(null);
const sizeError = ref("");

const openDialog = () => {
  fileInput.value.click();
}
const clear = () => {
  fileName.value = ""
  fileInput.value.value = null
  emit("update:file", null)
}

const onFile = async (e) => {
  const file = e.target?.files[0];

  if (!file) {
    return;
  }

  fileName.value = file.name;

  if (file.size > props.maxSize) {
    sizeError.value = `Max size is ${convertByte2MB(props.maxSize, "b", false)}`;
  } else {
    emit("update:file", file);
  }
}

const random = Math.random().toString(32).slice(0, 8);
</script>

<template>
  <div
    :class="attrs.class"
    class="ui-file-upload"
    :style="attrs.style"
  >
    <v-btn
      class="ui-file-upload__btn"
      icon
      color="secondary"
      @click="() => openDialog()"
    >
      <v-icon>
        mdi-upload
      </v-icon>
    </v-btn>

    <div class="ui-file-upload__input-box">
      <UiInputWrapper
        :label="label"
        hints="Maximum size 3MB, Accept: PNG"
        :errors="errors || sizeError"
      >
        <input class="ui-file-upload__filename" type="text" :value="fileName" placeholder=" " readonly />

        <template v-if="fileName" #input-slot-right>
          <button
            class="ui-search-input__clear"
            type="button"
            @click="() => clear()"
          >
            <v-icon class="ui-search-input__ico">mdi-close</v-icon>
          </button>
        </template>
      </UiInputWrapper>

      <label
        v-if="!fileName"
        class="ui-file-upload__dialog-trigger"
        :for="`file-upload-${random}`"
      >
      </label>

      <input
        v-bind="filteredAttrs"
        ref="fileInput"
        class="ui-file-upload__file-up-input"
        :id="`file-upload-${random}`"
        type="file"
        name="file-upload"
        @change="(e) => onFile(e)"
      >
    </div>

    <slot name="bottom" />
  </div>
</template>

<style lang="scss">
.ui-file-upload {
  display: flex;
  align-items: center;
  gap: .25rem;

  &__input-box {
    position: relative;
    flex: 1;
  }

  &__btn {
    margin-bottom: 8px;
  }

  &__filename {
    cursor: default;
  }

  &__dialog-trigger {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__file-up-input {
    display: none;
  }
}
</style>

