<template>
  <div style="display: contents;">
    <XDataTable
        :headers="headers"
        :items-request="cockpitExplorerService.getDevicePoolsTable"
        :crud-requests="crudRequests"
        :item="item"
        @update:item="item = $event"
        item-name="Device Pool"
        :default-item="createDefaultItem">
      <template #dialog-form="{item}">
        <div class="device-pool-dialog">
          <XTextField v-model="item.name" label="Name" required max-length="50"/>
          <XSelect
              v-model="item.businessWorkflow.id"
              label="Business Flow"
              :items="businessWorkflows"
              item-value="id"
              item-text="name"
              required/>
          <XSelect
              v-model="item.data.contact"
              label="Contact"
              :items="managers"
              :item-text="formatManagerText"
              required/>
          <SimpleListEditor
              v-model="item.projects"
              type="select"
              label="Project"
              item-text="name"
              required
              :items="projects"/>
        </div>
      </template>
    </XDataTable>
    <DevicePoolNotEmptyDialog
        v-model="devicePoolNotEmptyDialog"
        :device-pool-id="devicePoolId"
        @input="devicePoolNotEmptyDialog = $event"/>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';
import XTextField from '@/components/basic/XTextField.vue';
import XSelect from '@/components/basic/XSelect.vue';
import userService from '@/js/services/UserService';
import SimpleListEditor from '@/components/basic/SimpleListEditor.vue';
import DevicePoolNotEmptyDialog from '@/components/specific/Explorer/AdditionalDialogs/DevicePoolNotEmptyDialog.vue';

export default {
  name: 'DevicePoolsView',
  components: {
    DevicePoolNotEmptyDialog,
    SimpleListEditor,
    XSelect,
    XTextField,
    XDataTable,
  },
  data() {
    return {
      cockpitExplorerService: cockpitExplorerService,
      item: this.createDefaultItem(),
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Business Workflow',
          value: 'businessWorkflow.name',
        },
      ],
      businessWorkflows: [],
      managers: [],
      projects: [],
      devicePoolNotEmptyDialog: false,
      devicePoolId: 0,
    };
  },
  created() {
    cockpitExplorerService.getBusinessWorkflows((businessWorkflows) => {
      this.businessWorkflows = businessWorkflows;
    });
    userService.getOrganizationManagers((managers) => {
      this.managers = managers;
    });
    userService.getProjectNamesByOrganization((projects) => {
      this.projects = projects;
    });
  },
  computed: {
    crudRequests() {
      return {
        create: {
          request: cockpitExplorerService.createDevicePool,
        },
        update: {
          request: cockpitExplorerService.updateDevicePool,
        },
        delete: {
          request: cockpitExplorerService.deleteDevicePool,
          errorHandler: (error, status, item) => {
            if (status === 409) {
              this.devicePoolId = item.id;
              this.devicePoolNotEmptyDialog = true;
            }
          },
        },
      };
    },
  },
  methods: {
    createDefaultItem() {
      return {
        name: '',
        businessWorkflow: {
          id: 0,
          name: '',
        },
        data: {
          contact: null,
        },
        projects: [],
      };
    },
    formatManagerText(manager) {
      return `${manager.firstName} ${manager.lastName} (${manager.email})`;
    },
  },
};
</script>

<style scoped>
.device-pool-dialog {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
