<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
  import { computed, defineEmits, defineProps, useAttrs } from "vue";
  import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue";

  // type Item = Record<string, string>
  // type Props = {
  //   items: Array<string | Item>
  //   getKeyBy: string
  //   getValueBy: string
  //   modelValue: string
  //   errors?: string
  //   hintText?: string
  //   isLoading?: boolean
  //   label: string
  // }

  const props = defineProps({
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
    },
    modelValue: {
      type: String,
    },
    itemKey: {
      type: String,
      default: "",
    },
    getKeyBy: {
      type: String,
      default: "",
    },
    getValueBy: {
      type: String,
      default: "",
    },
    hintText: {
      type: String,
      default: "",
    },
    errors: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(["update:modelValue", "update:itemKey"]);

  const attrs = useAttrs();
  const filteredAttrs = (() => {
    // eslint-disable-next-line no-unused-vars
    const { class: _, style: __, ...otherAttrs } = attrs;
    return otherAttrs;
  })();

  const itemValues = computed(() => {
    if (props.items.length === 0) {
      return [];
    }
    if (!props.getValueBy) {
      return props.items;
    }
    return (props.items).map((item) => item[props.getValueBy]);
  });

  const updateModel = (event) => {
    const value = event.target.value || "";

    if (props.modelValue) {
      emit("update:modelValue", value);
    }

    if (!props.getKeyBy && !props.getValueBy) {
      return;
    }

    const selectedItem = (props.items).find((item) => item[props.getValueBy] === value);
    if (selectedItem) {
      emit("update:itemKey", selectedItem[props.getKeyBy]);
    } else {
      emit("update:itemKey", "");
    }
  };

  const selectValue = computed(() => {
    if (props.modelValue) {
      return props.modelValue;
    }
    if (props.itemKey) {
      const item = props.items.filter(i => i[props.getKeyBy].toString() === props.itemKey)[0]
      return item ? item[props.getValueBy] : "";
    }
    return "";
  })
</script>

<template>
  <UiInputWrapper
    class="ui-select"
    :class="attrs.class"
    :style="attrs.style"
    :label="label"
    :errors="errors"
    :is-loading="isLoading"
    :hints="hintText"
  >
    <select
      v-bind="filteredAttrs"
      :class="{
        'ui-select__select': true,
        'ui-select__select--selected': Boolean(selectValue),
      }"
      :value="selectValue"
      @change="(e) => updateModel(e)"
    >
      <option
        :key="Date.now()"
        disabled
        :selected="!selectValue"
        value=""
      >
        {{ label }}
      </option>

      <option
        v-for="(value, index) in itemValues"
        :key="index"
        :value="value"
      >
        {{ value }}
      </option>
    </select>

    <div class="ui-select__arrow-box">
      <div class="ui-select__arrow" />
    </div>
  </UiInputWrapper>
</template>

<style lang="scss">
  .ui-select {
    &:has(select[disabled]) {
      cursor: not-allowed;
    }

    &__select {
      position: relative;

      color: #00000099;

      &--selected {
        color: rgba(0, 0, 0, 0.87);
      }

      &[disabled] {
        cursor: not-allowed;
      }
    }

    &__arrow-box {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__arrow {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #00000099;
      pointer-events: none;
    }
  }
</style>
