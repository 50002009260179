<template>
  <div style="display: contents;">
    <XDataTable
        title="Received Packets"
        :headers="headers"
        :items-request="subscriberMailbox.getReceivedPackets"
        search>
      <template #[`item.data`]="{resultIndex, itemsRequest, itemsParams, count}">
        <PacketIcon
            :result-index="resultIndex" :items-request="itemsRequest" :items-params="itemsParams" :count="count"/>
      </template>
    </XDataTable>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import PacketIcon from '@/components/specific/PacketIcon.vue';
import subscriberMailbox from "@/js/services/SubscriberMailboxService";
import {unixToDateTimeString} from '@/js/general';

export default {
  name: 'ReceivedPacketsView',
  computed: {
    subscriberMailbox() {
      return subscriberMailbox
    }
  },
  components: {
    PacketIcon,
    XDataTable,
  },
  data() {
    return {
      packetDialog: false,
      selectedPacket: null,
      openedRowIndex: 0,
      headers: [
        {
          text: 'Received Time',
          value: 'receivedTime',
          formatter: v => unixToDateTimeString(v / 1000000),
          sortable: true,
        },
        {
          text: 'Client IP',
          value: 'clientIp',
          sortable: true,
        },
        {
          text: 'Active Probe',
          value: 'activeProbe.name',
          sortable: true,
        },
        {
          text: 'Server',
          value: 'server',
          formatter: v => `${v.ip}:${v.port}`,
          sortable: true,
        },
        {
          text: 'Protocol',
          value: 'protocol',
          sortable: true,
        },
        {
          text: 'Data',
          value: 'data',
          width: 100,
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
