<script setup>
import { ref, watch } from 'vue';
import requests from '@/js/requests';
import XThrobber from '@/components/basic/XThrobber.vue';
import { useStore } from '@/store';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  public: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['result']);

const store = useStore();

const result = ref(null);
const loading = ref(true);

const loadData = () => {
  if (!props.public) {
    requests
        .phpServiceGetRequest(props.url, (response) => {
          result.value = response.result;
          store.commit('setCurrentPhpServiceViewUrl', props.url);
          emit('result', response.result);
        })
        .finally(() => {
          loading.value = false;
        });
  } else {
    requests
        .publicPhpServiceGetRequest(props.url, (response) => {
          result.value = response.result;
          store.commit('setCurrentPhpServiceViewUrl', props.url);
          emit('result', response.result);
          loading.value = false;
        })
        .finally(() => {
          loading.value = false;
        });
  }
};

watch(
    () => props.url,
    () => {
      loadData();
    },
    { immediate: true }
);
</script>

<template>
  <div v-if="result && !loading">
    <slot :result="result" />
  </div>
  <XThrobber centered v-else-if="loading" />
</template>

<style scoped>
</style>
