<template>
  <div style="display: contents;">
    <XDataTable
        title="Chart Templates"
        :headers="headers"
        :item="item"
        :default-item="getDefaultItem"
        item-name="Chart Template"
        :items-request="getTemplates"
        :crud-requests="crudRequests"
        dialog-width="800"
        search
        @update:item="(_item) => syncItem(_item)">
      <template #dialog-form>
        <div class="chart-template-form">
          <XTextField label="Name" v-model="item.name" max-length="30" required validate-immediately/>
          <template v-if="meta">
            <XSelect
              label="Metric"
              v-model="item.metric"
              :items="metrics"
              required
              no-preselect
            />

            <XSelect
              v-if="item.metric === 'breitbandmessung'"
              label="Type"
              v-model="item.type"
              :items="chartTypes"
            />

            <XSelect
              v-if="groupByItems.length && !isExplorerMapByTagMetric"
              label="Group By"
              v-model="item.groupBy"
              :items="groupByItems"
            />

            <ChartFilterEditor
              v-if="!isExplorerMapByTagMetric"
              v-model="item.filter"
              :meta="meta"
              :metric="item.metric"
            />

            <ExplorerMapByTagFilterEditor
              v-else-if="meta[CHART_METRIC_NAME.EXPLORER_MAP_BY_TAG]?.tags"
              :map-configuration.sync="item.explorerMapByTagConfiguration"
              :tags="meta[CHART_METRIC_NAME.EXPLORER_MAP_BY_TAG]?.tags"
            />

            <XSelect
              v-if="!isExplorerMapByTagMetric"
              multiple chips
              label="Fields"
              v-model="item.fields"
              :items="fields"
              required
            />
          </template>
          <XThrobber v-else centered/>
        </div>
      </template>

      <template #[`item.filter`]="{value}">
        <div v-if="value" class="filters">
          <div v-for="(filter, i) of getFilterValuesByTag(value)" :key="i" class="filter-row">
            <span class="filter-tag">{{ filter.tag }}:</span> {{ filter.value.replaceAll(',', ', ') }}
          </div>
        </div>
      </template>
    </XDataTable>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import XDataTable from '@/components/basic/XDataTable.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XSelect from '@/components/basic/XSelect.vue';
import ChartFilterEditor from '@/components/specific/ChartFilterEditor.vue';
import XThrobber from '@/components/basic/XThrobber.vue';
import chartService from '@/js/services/ChartService';
import ExplorerMapByTagFilterEditor from '@/components/specific/ExplorerMapByTagFilterEditor.vue';
import {objectToArray} from '@/js/general';

const CHART_METRIC_NAME = Object.freeze({
  EXPLORER_MAP_BY_TAG: 'ExplorerMapByTag',
})

export default defineComponent({
  name: 'ChartTemplatesView',
  components: {
    XThrobber,
    ChartFilterEditor,
    XSelect,
    XTextField,
    XDataTable,
    ExplorerMapByTagFilterEditor,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Metric',
          value: 'metric',
          sortable: true,
        },
        {
          text: 'Group By',
          value: 'groupBy',
          sortable: true,
        },
        {
          text: 'Filter',
          value: 'filter',
        },
      ],
      chartTypes: [
        {
          text: 'Default',
          value: '',
        },
        {
          text: 'Scatter',
          value: 'scatter',
        },
      ],
      item: this.getDefaultItem(),
      meta: null,
    };
  },
  setup() {
    const crudRequests = {
      create: {
        request: chartService.createTemplate,
      },
      update: {
        request: chartService.updateTemplate,
      },
      delete: {
        request: chartService.deleteTemplate,
      },
    }

    return {
      crudRequests,
      getTemplates: chartService.getTemplates,
      CHART_METRIC_NAME,
    }
  },
  created() {
    chartService.getChartConfigMeta(meta => {
      this.meta = meta;
    });
  },
  computed: {
    metrics() {
      if (!this.meta) return [];
      const metrics = Object.keys(this.meta).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      return metrics.map(key => ({
        text: key,
        value: key,
      }));
    },
    fields() {
      if (!this.meta || !this.item.metric) return [];
      return [...((this.meta[this.item.metric] || {}).fields || [])].sort(
          (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    },
    groupByItems() {
      if (!this.meta || !this.item.metric || !this.meta[this.item.metric].tags) return [];
      return Object.keys(this.meta[this.item.metric].tags);
    },
    isExplorerMapByTagMetric() {
      return this.item.metric === CHART_METRIC_NAME.EXPLORER_MAP_BY_TAG
    },
  },
  methods: {
    getFilterValuesByTag(filter) {
      return objectToArray(filter, 'tag');
    },
    syncItem(_item) {
      this.item = _item
    },
    getDefaultItem() {
      return {
        name: '',
        metric: '',
        type: '',
        groupBy: '',
        filter: {},
        fields: [],
      };
    },
  },
});
</script>

<style scoped>
.chart-template-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-tag {
  font-weight: bold;
}
</style>
