<template>
  <primaryButton
    class="mr-2"
    :attributesArray="functionElement['attributesArray']"
    type="button"
    :value="functionElement['functionName']"
    @click="logout"
    :label="functionElement['functionName']"
    color="save"
    :disabled="loading"
    :loading="loading"
    icon="mdi-logout"
  ></primaryButton>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: ["isValid", "loading", "functionElement", "result"],
  components: {
    primaryButton,
  },
  methods: {
    logout() {
      this.$router.push({ name: "logout" });
    },
  },
};
</script>
