<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="localStoredValue"/>
  </div>
</template>
<script setup>

import {computed, defineEmits} from "vue"

import fieldsRows from '@/commonComponents/fieldsRows.vue';
import testStepDefault from '@/cfg/teststep-default.json';

// eslint-disable-next-line no-undef
const props = defineProps(['result', 'stepStoredValue', 'stepType', 'stepID'])
const emit = defineEmits(['stored-value-changed'])


const updateFieldValue = (field, value) => {
  let localStoredStep = {...props.stepStoredValue};
  localStoredStep[field] = value;
  emit('stored-value-changed', localStoredStep);
}

const localStoredValue = computed({
  get() {
    let stepStoredValue = props.stepStoredValue;
    if (!props?.stepStoredValue?.startWithinMinutes) {
      stepStoredValue = JSON.parse(JSON.stringify({...testStepDefault[props.stepType]}));
      emit('stored-value-changed', stepStoredValue);
    }
    return stepStoredValue;
  },
  set(newVal) {
    emit('stored-value-changed', newVal);
  }
});

const templateContent = computed(() => {
  return {
    fields: {
      timeLine: {
        type: 'line',
        fields: {
          startWithinMinutes: {
            type: 'number',
            name: 'Start Within Minutes',
            fieldAttrInput: {
              class: '',
            },
            colAttrs: {style: 'max-width:170px', class: ''},
          },
          finishedWithinMinutes: {
            type: 'number',
            name: 'Finished Within Minutes',
            fieldAttrInput: {
              class: '',
            },
            colAttrs: {style: 'max-width:170px', class: ''},
          },
          maxDuration: {
            type: 'number',
            name: 'Maximum Duration (s)',
            fieldAttrInput: {
              class: '',
            },
            colAttrs: {style: 'max-width:200px', class: ''},
          },
        }
      },
      parameters: {
        type: 'text',
        inputFieldFormatter: 'explorerSimInsertParameter',
      },
      res: {
        type: 'text',
        inputFieldFormatter: 'explorerResult',// this is template name, be aware here most steps' on result section comes here!
      },
    },
    stepType: props.stepType,
    stepID: props.stepID,
    disableAllInputs: props.result.disableAllInputs,
    contentID: props.stepType,
  }
})

</script>