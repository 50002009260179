<script>
import { defineComponent } from "vue"
export default defineComponent({
  inheritAttrs: false,
})
</script>

<script
  setup
>
  import { useAttrs, computed, defineProps, defineEmits } from "vue"
  // type Props = {
  //   modelValue: boolean | string[]
  //   dark?: boolean
  // }

  const props = defineProps({
    modelValue: {
      type: [Boolean, Array],
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(["update:modelValue"])

  const attrs = useAttrs()

  const onChange = (e) => {
    const isChecked = e.target.checked
    if (Array.isArray(props.modelValue)) {
      const newValue = isChecked
        ? [...props.modelValue, attrs.value]
        : props.modelValue.filter((item) => item !== attrs.value)

      emit("update:modelValue", newValue)
    } else {
      emit("update:modelValue", isChecked)
    }
  }
  const isChecked = computed(() => {
    if (Array.isArray(props.modelValue)) {
      return props.modelValue.includes(attrs.value)
    }
    return props.modelValue
  })
</script>

<template>
  <label
    :class="{
      'ui-switch': true,
      'ui-switch--on': isChecked,
      'ui-switch--dark': dark,
      'ui-switch--disabled': attrs.disabled,
      [attrs.class]: Boolean(attrs.class),
    }"
    :for="attrs.id"
  >
    <input
      :id="attrs.id"
      type="checkbox"
      class="ui-switch__input"
      :checked="isChecked"
      :value="attrs.value"
      :disabled="attrs.disabled"
      hidden
      @change="(e) => onChange(e)"
    >

    <span
      class="ui-switch__track"
      :aria-hidden="true"
    >
      <span class="ui-switch__thumb" />
    </span>

    <span class="ui-switch__label-txt">
      <slot />
    </span>
  </label>
</template>

<style lang="scss">
  $track-width: 36px;
  $white: #fff;
  $light-gray-2: rgba(0, 0, 0, 0.28);
  $primary-dark-2: #002960;
  $primary: #2b5593;
  $primary-light-2: rgba(43, 85, 147, 0.6);
  $black-text: rgba(0, 0, 0, 0.87);

  .ui-switch {
    $root: &;

    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &__track {
      position: relative;
      width: $track-width;
      height: 14px;
      border-radius: 8px;
      margin-right: 10px;
      background-color: $light-gray-2;
      transition: background-color 0.2s;
    }

    &__thumb {
      position: absolute;
      top: -3px;
      left: 0;
      z-index: 1;

      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $white;
      transition: transform 0.2s;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
            0 1px 10px 0 rgba(0, 0, 0, 0.12);

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        border-radius: inherit;
        background-color: currentColor;
        transform-origin: center;
        scale: 0.2;
        opacity: 0;
      }
    }

    &--dark {
      #{$root}__track {
        background-color: $primary-dark-2;
      }

      #{$root}--on #{$root}__track {
        background-color: $primary;
      }
    }

    &:hover #{$root}__thumb::before {
      scale: 2.2;
      opacity: 0.2;
    }

    &--on #{$root}__track {
      background-color: $primary-light-2;

      #{$root}__thumb {
        transform: translateX(calc($track-width / 2));
        background-color: $primary;
      }
    }

    &__label-txt {
      min-height: 8px;
      font-size: 1rem;
      line-height: 1.2;
      color: $black-text;
      text-align: left;
      user-select: none;
    }
  }
</style>
