<template>
  <div class="test-cases">
    <XDataTable
        table-name="Configure And Run Tests "
        :headers="headers"
        dialog-width="1800"
        unpadded-dialog
        history
        show-total
        :refresh="refresh"
        :select-actions="selectActions"
        :select-row-actions="selectRowActions"
        search
        sort-by="name"
        :search-text="searchText"
        :crud-requests="crudRequests"
        :items-request="testCaseInfo.getTestcases"
        :items-request-params="itemsRequestParams"
        :row-actions="rowActions"
        :additional-row-actions="additionalRowActions"
        @search="searchText = $event"
        @update-item="item = $event"
        height="84vh"
        @refresh="$emit('refresh')">
      <template #after-new>
        <AddField
            path="serve.php?f=testing&f2=configureAndRunTests"
            v-on="$listeners">
          <XBtn text="New" icon="mdi-plus" color="primary"/>
        </AddField>
      </template>
      <template #before-search>
        <div class="filter-by-categories">
          <XSelect
              v-model="categories"
              :items="allCategories"
              label="Categories"
              width="400"
              deletable-chips
              multiple
              dense
              combobox
              chips>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </XSelect>
        </div>
      </template>
      <template #[`item.name`]="{ row, value }">
        <AddField
            path="serve.php?f=testing&f2=configureAndRunTests"
            :additionalAttributes="{
            updateTable: false,
            function: 'getInsertedFieldsEdit',
            n_id: row.id,
            n_id_key: shaKey(row.id)}"
            :dialogAttributes="{}"
            v-on="$listeners">
          {{ value }}
        </AddField>
      </template>
      <template #[`item.description`]="{ row, value}">
        <AddField
            path="serve.php?f=testing&f2=configureAndRunTests"
            :additionalAttributes="{
            updateTable: false,
            function: 'getInsertedFieldsEdit',
            n_id: row.id,
            n_id_key: shaKey(row.id)}"
            :dialogAttributes="{}"
            v-on="$listeners">
          {{ value }}
        </AddField>
      </template>
      <template #[`item.subscriber`]="{value, row }">
        <ClickableText :text="value ? `${row.msisdn}: ${value}` : `${row.msisdn}`" @click="editSubscriber(row)"/>
      </template>
      <template #[`item.categories`]="{value}">
        <div class="categories">
          <a
              v-for="(category, i) in value.split(',').filter((cat) => cat !== '').map(function(c) { return c.trim()})"
              :key="i"
              title="Click to add in Category Filter"
              @click="addCategory(category)">{{ category }}</a>
        </div>
      </template>
    </XDataTable>

    <CopyTestCaseDialog v-model="copyTestCaseDialog" :testCasesIds="selectedTestCases"/>
    <CategoryDialog v-model="categoryDialog" :rows="testCases" @save="refreshTable"/>
    <ScheduleTestsDialog v-model="scheduleTestsDialog" :scheduledTest="testCase"/>
    <SubscriberEditDialog v-model="subscriberEditDialog" :row="subscriberRow"/>
    <SetTimeAndRunDialog v-model="setTimeAndRunDialog" :testCasesIds="selectedTestCases"/>
    <MessageSequenceChartDialog v-model="msgSequenceChartDialog" :testCase="testCase"/>
    <TestCaseExecutor v-model="testCaseExecutorDialog" :row="subscriberRow"/>
    <ReallyDeleteDialog v-model="reallyDeleteDialog" :item-name="itemsToDelete" @yes="deleteItems(selectedTestCases)"/>
    <startParametersDialog
        v-if="parameterDialog"
        v-model="startParameters"
        :parameterDialog="parameterDialog"
        :loading="loading"
        @confirmation-ok="executeRunSelected"
        :testNames="testNames"/>
  </div>
</template>

<script>
import AddField from '@/commonComponents/addField.vue';
import ClickableText from '@/components/basic/ClickableText';
import CopyTestCaseDialog from '@/components/specific/CopyTestCaseDialog.vue';
import CategoryDialog from '@/components/specific/CategoryDialog.vue';
import SetTimeAndRunDialog from '@/components/specific/SetTimeAndRunDialog.vue';
import ScheduleTestsDialog from '@/components/specific/ScheduleTestsDialog.vue';
import {shaKey} from '@/js/helper';
import SubscriberEditDialog from '@/components/specific/SubscriberEditDialog.vue';
import MessageSequenceChartDialog from '@/components/specific/MessageSequenceChartDialog.vue';
import TestCaseExecutor from '@/components/specific/TestCaseExecutor.vue';
import XBtn from '@/components/basic/XBtn';
import XDataTable from '@/components/basic/XDataTable';
import XSelect from '@/components/basic/XSelect';
import ReallyDeleteDialog from '@/components/extended/ReallyDeleteDialog.vue';
import startParametersDialog from '@/components/legacy/startParametersDialog.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import testCaseInfo from "@/js/services/TestCaseInfoService";
import requests from '@/js/requests';

export default {
  name: 'TestCaseView',
  components: {
    startParametersDialog,
    ReallyDeleteDialog,
    AddField,
    ClickableText,
    CategoryDialog,
    CopyTestCaseDialog,
    MessageSequenceChartDialog,
    ScheduleTestsDialog,
    SubscriberEditDialog,
    SetTimeAndRunDialog,
    TestCaseExecutor,
    XBtn,
    XDataTable,
    XSelect,
  },
  data() {
    return {
      allCategories: [],
      categories: [],
      categoryDialog: false,
      copyTestCaseDialog: false,
      dialog: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          width: 400,
        },
        {
          text: 'Description',
          value: 'description',
          sortable: true,
          // width: 1600,
        },
        {
          text: 'Subscriber',
          value: 'subscriber',
          sortable: true,
        },
        {
          text: 'Categories',
          value: 'categories',
          sortable: false,
        },
        {
          text: 'Active Probes',
          value: 'activeProbes',
          sortable: true,
          width: 200,
        },
      ],
      itemsToDelete: '',
      loading: false,
      msgSequenceChartDialog: false,
      multipleRows: false,
      reallyDeleteDialog: false,
      refresh: 0,
      parameterDialog: false,
      scheduleTestsDialog: false,
      searchText: '',
      selectedRow: undefined,
      selectedRowCategories: [],
      selectedRows: [],
      selectedTestCases: [],
      selectedTestCasesNames: [],
      setTimeAndRunDialog: false,
      startParameters: [],
      subscriberEditDialog: false,
      subscriberRow: undefined,
      testCase: {
        name: '',
        times: '{"daily":["*:00:00"],"weekly":{}}', // '{"daily":["*:*/1:00"],"weekly":{}}',
      },
      testCaseExecutorDialog: false,
      testCases: [],
      testNames: undefined,
    };
  },
  created() {
    this.getAllCategories();
  },
  computed: {
    testCaseInfo() {
      return testCaseInfo
    },
    crudRequests() {
      return {
        delete: {
          request: testCaseInfoService.deleteTestCase,
        },
      };
    },
    itemsRequestParams() {
      return [this.categoriesFilter];
    },
    rowActions() {
      return [
        {
          icon: 'mdi-play',
          click: (item) => {
            this.openTestExecutorDialog(item);
          },
        },
      ];
    },
    additionalRowActions() {
      return [
        {
          text: 'Copy to Another Project',
          icon: 'mdi-content-copy',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (item) => {
            this.multipleRows = false;
            this.openCopyTestCaseDialog([item.id]);
          },
        },
        {
          text: 'Category',
          icon: 'mdi-tag-multiple-outline',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (item) => {
            this.multipleRows = false;
            this.openTagListDialog({id: item.id, categories: item.categories});
          },
        },
        {
          text: 'Set Time and Run',
          icon: 'mdi-calendar-clock-outline',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (item) => {
            this.openSetTimeAndRunDialog(item);
          },
        },
        {
          text: 'Schedule',
          icon: 'mdi-clock-outline',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (item) => {
            this.multipleRows = false;
            this.openScheduleTestsDialog(item);
          },
        },
        {
          text: 'Show Message Sequence Chart',
          icon: 'mdi-chart-timeline',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (item) => {
            this.openMsgSequenceChartDialog(item);
          },
        },
      ];
    },
    selectActions() {
      return [
        {
          icon: 'mdi-play',
          click: (items) => {
            console.log('Items: ', items);
            this.executeRunSelected(items);
          },
        },
      ];
    },
    selectRowActions() {
      return [
        {
          text: 'Copy to Another Project',
          icon: 'mdi-content-copy',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (selectedItems) => {
            selectedItems = Object.keys(selectedItems).filter(key => selectedItems[key]);
            this.openCopyTestCaseDialog(selectedItems);
            console.log('SelectedItems: ', selectedItems);
          },
        },
        {
          text: 'Category',
          icon: 'mdi-tag-multiple-outline',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (selectedItems) => {
            selectedItems = Object.keys(selectedItems).filter(key => selectedItems[key]);
            this.openTagListDialog(selectedItems);
          },
        },
        {
          text: 'Set Time and Run',
          icon: 'mdi-calendar-clock-outline',
          hoverColors: {
            background: 'row-item-hover',
          },
          click: (selectedItems) => {
            selectedItems = Object.keys(selectedItems).filter(key => selectedItems[key]);
            this.openSetTimeAndRunDialog(selectedItems);
            console.log('Items: ', selectedItems);
          },
        },
        {
          text: 'Delete',
          icon: 'mdi-delete',
          background: {
            text: 'black',
            background: 'delete',
          },
          hoverColors: {
            text: 'white',
            background: 'delete',
          },
          click: (selectedItems) => {
            selectedItems = Object.keys(selectedItems).filter(key => selectedItems[key]);
            this.selectedTestCases.length = 0; // reset
            this.selectedTestCasesNames.length = 0; // reset
            const promises = selectedItems.map(testCaseId => {
              return new Promise(resolve => {
                testCaseInfoService.getTestCase(Number(testCaseId), (testCase) => {
                  this.selectedTestCases.push(testCase);
                  this.selectedTestCasesNames.push(testCase.name);
                  resolve();
                });
              });
            });

            Promise.all(promises).then(() => {
              this.itemsToDelete = ''; //reset
              this.itemsToDelete = this.getItemsToDeleteNames(this.selectedTestCasesNames);
              this.reallyDeleteDialog = true;
            });
          },
        },
      ];
    },
    categoriesFilter() {
      return this.categories.join(',');
    },
  },
  methods: {
    addCategory(category) {
      if (!this.categories.includes(category)) this.categories.push(category);
    },
    deleteItems(itemsArray) {
      for (const item of itemsArray) {
        testCaseInfoService.deleteTestCase(item.id, () => {
          console.log('Item: ', item.name, ' has been deleted.');
        });
      }
    },
    editSubscriber(row) {
      this.subscriberRow = row;
      this.subscriberEditDialog = true;
    },
    executeRunSelected(rows) {
      let selected = [];
      selected = rows.map(str => parseInt(str, 10));
      if (selected.length > 0) {
        this.$emit('show-warning', false);
        this.loadingOverlay = true;
        var options = {
          function: 'executeSelectedTest',
          selected: selected,
          requestType: 'ajax',
          startParameters: this.startParameters,
        };
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php?f=testing&f2=testsExecuteApi',
          data: options,
        })
            .then((response) => {
              if (response.data.result.responseCode == '201') {
                window.location.href = response.data.result.redirect;
                caller.parameterDialog = false;
              } else if (response.data.result.responseCode == '300') {
                caller.parameterDialog = true;
                caller.startParameters =
                    response.data.result.body.startParameters;
                caller.testNames = response.data.result.testNames;
              } else {
                caller.parameterDialog = false;
                caller.startParameters = [];
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      } else {
        this.$emit('show-warning', true);
      }
    },
    getItemsToDeleteNames(items) {
      if (items.length === 0) {
        return '';
      } else if (items.length === 1) {
        return items[0];
      } else if (items.length === 2) {
        return items.join(' and ');
      } else {
        const lastItem = items.pop();
        return items.join(', ') + ', and ' + lastItem;
      }
    },
    openCopyTestCaseDialog(rows) {
      this.selectedTestCases = rows;
      this.copyTestCaseDialog = true;
    },
    openMsgSequenceChartDialog(row) {
      testCaseInfoService.getTestCase(row.id, (testCase) => {
        this.testCase = testCase;
        this.msgSequenceChartDialog = true;
      });
    },
    openTagListDialog(rows) {
      if (typeof rows === 'object' && !Array.isArray(rows)) {
        this.testCases = [rows];
        this.categoryDialog = true;
      } else if (Array.isArray(rows) && rows.length > 0) {
        this.testCases = rows;
        this.categoryDialog = true;
      }
    },
    openTestExecutorDialog(row) {
      this.subscriberRow = row;
      this.testCaseExecutorDialog = true;
    },
    openSetTimeAndRunDialog(row) {
      this.selectedTestCases = [row.id];
      this.setTimeAndRunDialog = true;
    },
    openScheduleTestsDialog(row) {
      testCaseInfoService.getScheduledTestFromTestCase(row.id, (scheduledTestCase) => {
        this.testCase = scheduledTestCase;
        this.testCase.testCase.id = row.id;
        // this.testCase.testCase.name = row['description'] ? row['name']+': '+row['description'] : row['name']
        this.scheduleTestsDialog = true;
      });
    },
    getAllCategories() {
      testCaseInfoService.getCategories((allCategories) => {
        this.allCategories = allCategories.filter(
            // remove whitespace elements
            (cat) => cat !== '').map(
            function (categories) {
              return categories.trim(); // remove trailing whitespaces
            });

        // remove duplicates
        this.allCategories = [...new Set(this.allCategories)].sort(
            (a, b) => a.localeCompare(b), // sort strictly alphabetically
        );
      });
    },
    refreshTable() {
      this.refresh = 2;

      // setz den Wert auf 0 zurück
      setTimeout(function () {
        this.refresh = 0;
      }, 3000); // nach 3 Sekunden
    },
    shaKey(msg) {
      return shaKey(msg);
    },
  },
};
</script>

<style scoped>
.test-cases {
  height: 100%;
  padding-top: 12px;
}

.categories {
  display: flex;
  gap: 7px;
}

a:hover {
  color: #2196f3;
}

</style>
