var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ui-input-wrapper",class:_vm.$attrs.class,style:(_vm.$attrs.style)},[_c('div',{staticClass:"ui-input-wrapper__info-box"},[_c('div',{staticClass:"ui-input-wrapper__info-slot"}),(_vm.$slots['top-right-info'])?_c('div',{staticClass:"ui-input-wrapper__info-slot ui-input-wrapper__info-slot--right"},[_vm._t("top-right-info")],2):_vm._e()]),_c('div',{staticClass:"ui-input-wrapper__input-box"},[(_vm.$slots.left)?_c('div',{staticClass:"ui-input-wrapper__left-box"},[_vm._t("left")],2):_vm._e(),_c('label',_vm._b({class:{
        'ui-input-wrapper__label': true,
        'ui-input-wrapper__label--error': (_vm.errors && _vm.errors.length) || !_vm.isValid,
        'ui-input-wrapper__label--focused': _vm.isFocused,
      }},'label',_vm.getFilteredAttributes(),false),[_c('div',{class:{ 'ui-input-wrapper__input-slot': true, 'ui-input-wrapper__input-slot--focused': _vm.isFocused }},[_vm._t("default"),(_vm.$slots['input-slot-right'])?_c('div',{staticClass:"ui-input-wrapper__input-slot-right",class:{
            'ui-input-wrapper__input-slot-right--error': (_vm.errors && _vm.errors.length) || !_vm.isValid,
          }},[_vm._t("input-slot-right")],2):_vm._e()],2),_c('span',{class:{
          'ui-input-wrapper__label-text': true,
          'ui-input-wrapper__label-text--error': (_vm.errors && _vm.errors.length) || !_vm.isValid,
          'ui-input-wrapper__label-text--transform': _vm.isNotEmpty
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"ui-input-wrapper__loading-bar-container"},[(_vm.isLoading)?_c('div',{staticClass:"ui-input-wrapper__loading-bar"}):_vm._e()])]),(_vm.$slots.right)?_c('div',{staticClass:"ui-input-wrapper__right-box"},[_vm._t("right")],2):_vm._e()]),_c('div',{staticClass:"ui-input-wrapper__info-box"},[_c('div',{staticClass:"ui-input-wrapper__info-slot"},[_c('Transition',{attrs:{"name":"slide-y-up","mode":"out-in"}},[(_vm.errors && _vm.errors.length)?_c('span',{key:"errors",staticClass:"ui-input-wrapper__error-msg"},[_vm._v(" "+_vm._s(_vm.errors)+" ")]):(_vm.hints && _vm.hints.length)?_c('span',{key:"hints",staticClass:"ui-input-wrapper__hints"},[_vm._v(" "+_vm._s(_vm.hints)+" ")]):_vm._e()])],1),(_vm.$slots['bottom-right-info'])?_c('div',{staticClass:"ui-input-wrapper__info-slot ui-input-wrapper__info-slot--right"},[_vm._t("bottom-right-info")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }